
const HomePage = () => {
    return (
        <div>
            <h1>Welcome to Jam!</h1>
            <p id="homePageText">This app will eventually provide users with features for collaboration in a common space</p>
        </div>
    );
}

export default HomePage;